module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_3zqfynnwqejpzj7tgthzfgkxgq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/konbuparrot.gif","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4debbbb1ac1e040c6274ef1ca6fd9671"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.11.0_encoding@0.1.13_gatsby@5.11.0_babel-eslint@10.1.0_eslint@7._btp236szxja5guifxtqhxxqrde/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_3zqfynnwqejpzj7tgthzfgkxgq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TPC3","short_name":"TPC3","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/konbuparrot.gif","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4debbbb1ac1e040c6274ef1ca6fd9671"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_meizzv4j6xadcyhni5lgkkvpq4/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.14.5_@emotion+react@11.11.1_@types+_b5n3dde2hlln7g6wg5idqdghum/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.11.1_@types+react@18.0.8_react@18.2.0__gats_5p63av5cra4nr7dunuag5gkm34/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.11.0_gatsby@5.11.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0._ghhj3yhozmwa6p6x7albn6umau/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YXCKGKXG93"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react-dom@18.2.0_react@18.2._w3jbt43wbns3d6wnmpmepmn2ym/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
